<template>
  <div class="v-snow-wrap">
    <img
      v-for="(img, index) in images"
      :key="index"
      class="v-flake"
      :src="img"
      alt=""
      style="display: none"
    />
    <canvas
      height="100%"
      ref="canvas"
      width="100%"
    />
  </div>
</template>

<script setup lang="ts">
/*based on github.com/peachananr/let_it_snow*/

import { useWindowSize, WindowSize } from '@arora/common'

type Flake = {
  speed: number
  velY: number
  velX: number
  x: number
  y: number
  size: number
  stepSize: number
  step: number
  angle: number
  opacity: number
}

const { windowSize } = useWindowSize()
const appConfig = useAppConfig()

const flakes = ref<Flake[]>([])
const imageItems = ref<HTMLImageElement[]>([])
const canvas = ref<HTMLCanvasElement | null>(null)
const context = ref<CanvasRenderingContext2D | null>(null)
const requestId = ref<number | null>(null)
const resizeTO = ref<ReturnType<typeof setTimeout> | null>(null)

const mX = ref<number>(-100)
const mY = ref<number>(-100)

const speed = computed<number>(() => {
  return Number.parseFloat(appConfig.VueSettingsPreRun.SnowSpeed.replaceAll(',', '.').trim())
})
const size = computed<number>(() => {
  return Number.parseFloat(appConfig.VueSettingsPreRun.SnowSize.replaceAll(',', '.').trim())
})
const opacity = computed<number>(() => {
  return Number.parseFloat(appConfig.VueSettingsPreRun.SnowOpacity.replaceAll(',', '.').trim())
})
const windPower = computed<number>(() => {
  return Number.parseFloat(appConfig.VueSettingsPreRun.SnowWind.replaceAll(',', '.').trim())
})
const images = computed<string[]>(() => {
  return appConfig.VueSettingsPreRun.SnowImages.split(',')
})
const flakeCount = computed<number>(() => {
  const amount = Number.parseInt(appConfig.VueSettingsPreRun.SnowAmount)
  switch (windowSize.value) {
    case WindowSize.xs:
      return amount / 4
    case WindowSize.sm:
      return amount / 3
    case WindowSize.md:
      return amount / 2
    case WindowSize.lg:
      return amount / 1.5
    case WindowSize.xl:
    default:
      return amount
  }
})

onMounted(() => {
  window.requestAnimationFrame =
    window.requestAnimationFrame ||
    function (callback) {
      return window.setTimeout(callback, 1000)
    }
  window.cancelAnimationFrame =
    window.cancelAnimationFrame ||
    function (id) {
      window.clearTimeout(id)
    }
  window.onresize = () => {
    if (resizeTO.value) clearTimeout(resizeTO.value)
    resizeTO.value = setTimeout(() => {
      init()
    }, 200)
  }

  if (images.value.length > 0) {
    init()
  } else {
    console.error("[vue] can't be snowing without the snowflakes!")
  }
})

function isImageOk(img: HTMLImageElement): boolean {
  // During the onload event, IE correctly identifies any images that
  // weren’t downloaded as not complete. Others should too. Gecko-based
  // browsers act like NS4 in that they report this incorrectly.
  if (!img.complete) {
    return false
  }
  // However, they do have two very useful properties: naturalWidth and
  // naturalHeight. These give the true size of the image. If it failed
  // to load, either of these should be zero.
  if (img.naturalWidth !== undefined && img.naturalWidth === 0) {
    return false
  }

  // No other way of checking: assume it’s ok.
  return true
}
function snow(): void {
  if (!context.value || !canvas.value) return
  context.value.clearRect(0, 0, canvas.value.width, canvas.value.height)
  for (let index = 0; index < flakeCount.value; index++) {
    const flake = flakes.value[index],
      minDistribution = 100,
      x = mX.value,
      x2 = flake.x,
      y = mY.value,
      y2 = flake.y
    const distribution = Math.sqrt((x2 - x) * (x2 - x) + (y2 - y) * (y2 - y))
    //let dx = x2 - x
    //let dy = y2 - y;
    if (distribution < minDistribution) {
      const force = minDistribution / (distribution * distribution),
        deltaV = force / 2,
        xComp = (x - x2) / distribution,
        yComp = (y - y2) / distribution
      flake.velX -= deltaV * xComp
      flake.velY -= deltaV * yComp
    } else {
      flake.velX *= 0.98
      if (flake.velY <= flake.speed) {
        flake.velY = flake.speed
      }
      switch (windPower.value) {
        case 0:
          flake.velX += Math.cos((flake.step += 0.05)) * flake.stepSize
          break
        default:
          flake.velX += 0.01 + windPower.value / 100
      }
    }
    const s = appConfig.VueSettingsPreRun.SnowColor
    const pattern = /^#([\da-fA-F]{2})([\da-fA-F]{2})([\da-fA-F]{2})$/
    const matches = pattern.exec(s)
    let rgb: string
    if (matches) {
      rgb = `${Number.parseInt(matches[1], 16)},${Number.parseInt(matches[2], 16)},${Number.parseInt(matches[3], 16)}`
    } else {
      rgb = '#FFF'
    }
    flake.y += flake.velY
    flake.x += flake.velX
    if (flake.y >= canvas.value.height || flake.y <= 0) {
      reset(flake)
    }
    if (flake.x >= canvas.value.width || flake.x <= 0) {
      reset(flake)
    }
    if (images.value.length === 0) {
      context.value.fillStyle = `rgba(${rgb},${flake.opacity})`
      context.value.beginPath()
      context.value.arc(flake.x, flake.y, flake.size, 0, Math.PI * 2)
      context.value.fill()
    } else {
      const imgItem = imageItems.value[index % imageItems.value.length]
      if (isImageOk(imgItem)) {
        context.value.drawImage(imgItem, flake.x, flake.y, flake.size * 2, flake.size * 2)
      }
    }
  }
  requestId.value = requestAnimationFrame(snow)
}
function reset(flake: Flake): void {
  if (!canvas.value) return
  if (windPower.value === 0) {
    flake.x = Math.floor(Math.random() * canvas.value.width)
    flake.y = 0
  } else if (windPower.value > 0) {
    const xArray = [Math.floor(Math.random() * canvas.value.width), 0]
    const yArray = [0, Math.floor(Math.random() * canvas.value.height)]
    const allArray = [xArray, yArray]
    const selectedArray = allArray[Math.floor(Math.random() * allArray.length)]
    flake.x = selectedArray[0]
    flake.y = selectedArray[1]
  } else {
    const xArray = [Math.floor(Math.random() * canvas.value.width), 0]
    const yArray = [canvas.value.width, Math.floor(Math.random() * canvas.value.height)]
    const allArray = [xArray, yArray]
    const selectedArray = allArray[Math.floor(Math.random() * allArray.length)]
    flake.x = selectedArray[0]
    flake.y = selectedArray[1]
  }
  flake.size = Math.random() * 3 + size.value
  flake.speed = Math.random() + speed.value
  flake.velY = flake.speed
  flake.velX = 0
  flake.opacity = Math.random() * 0.5 + opacity.value
}
function init(): void {
  if (requestId.value) cancelAnimationFrame(requestId.value)

  if (!canvas.value) return

  context.value = canvas.value.getContext('2d')
  canvas.value.width = window.innerWidth
  canvas.value.height = window.innerHeight

  for (let index = 0; index < flakeCount.value; index++) {
    const opacityLocal: number = Math.random() * 0.5 + opacity.value,
      sizeLocal: number = Math.random() * 3 + size.value,
      speedLocal: number = Math.random() + speed.value,
      x: number = Math.floor(Math.random() * canvas.value.width),
      y: number = Math.floor(Math.random() * canvas.value.height)

    const flake: Flake = {
      speed: speedLocal,
      velY: speedLocal,
      velX: 0,
      x: x,
      y: y,
      size: sizeLocal,
      stepSize: Math.random() / 30,
      step: 0,
      angle: 180,
      opacity: opacityLocal
    }

    flakes.value.push(flake)
  }
  const imageList: NodeListOf<HTMLImageElement> = document.querySelectorAll('.v-flake')
  for (const element of imageList) {
    imageItems.value.push(element)
  }
  snow()
}
</script>

<style lang="scss" scoped>
.v-snow-wrap {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  pointer-events: none;
  height: 100%;
  width: 100%;

  canvas {
    display: block;
    height: 100%;
    width: 100%;
  }
}
</style>
